import { env, envEnum } from "../../utils/environment";
import axios from "axios";

function getUserAPIUri() {
  if (env === envEnum.dev || env === envEnum.local)
    return "https://dev-user.vinsolutions.com/";
  if (env === envEnum.qa) return "https://qa-user.vinsolutions.com/";
  if (env === envEnum.staging) return "https://staging-user.vinsolutions.com/";
  return "https://user.vinsolutions.com/";
}

const baseURL = getUserAPIUri();

const api = axios.create({
  baseURL,
  timeout: 150000,
  headers: {
    "X-CoxAuto-ReturnNulls": "true",
    Accept: "application/vnd.coxauto.v1+json"
  },
  withCredentials: false
});

export function addUsersToDealers(jwt, bulkOperations) {
  // Add bearer token to header to auth with permissions service
  api.defaults.headers["Authorization"] = `Bearer ${jwt.accessToken}`;

  return api.post(`${baseURL}userdealers/bulk`, bulkOperations);
}
