// This entire file exists to hold a (hopefully temporary) hack per Alex McCabe.

// These hard-coded lists were provided by Alex McCabe and can be removed
// if we start supporting removing permissions from roles.
const adminPermissions = [
  "Access mobile apps",
  "Modify email templates",
  "Manage desking templates",
  "View desking management report",
  "Access desk log",
  "Access service appt. dashboard",
  "View gross",
  "View equity",
  "Access Facebook Messenger",
  "Access desking",
  "View vehicle cost",
  "Mark leads bad",
  "Mark leads lost",
  "Push to credit portal",
  "Access Customer Intelligence",
  "Access/Enable Enterprise Customer",
  "Can Approve Customer Override",
  "Access AMP Opportunity List",
  "View OEM Launchpad Incentives",
  "Enable Generative AI capabilities"
];

const managerPermissions = [
  "Modify form packets",
  "Modify email templates",
  "Reactivate leads",
  "Mark vehicle as no longer owns",
  "Approve vehicle as no longer owns",
  "Copy customers",
  "Access mobile apps",
  "Manage desking templates",
  "View desking management report",
  "Access desk log",
  "Access service appt. dashboard",
  "View gross",
  "View equity",
  "Access Facebook Messenger",
  "Access desking",
  "View vehicle cost",
  "Mark leads bad",
  "Mark leads lost",
  "Push to credit portal",
  "Access Customer Intelligence",
  "Access/Enable Enterprise Customer",
  "Can Approve Customer Override",
  "Access AMP Opportunity List",
  "View OEM Launchpad Incentives",
  "Enable Generative AI capabilities"
];

const salesPersonPermissions = [
  "Modify form packets",
  "Modify email templates",
  "Reactivate leads",
  "Mark vehicle as no longer owns",
  "Approve vehicle as no longer owns",
  "Access mobile apps",
  "Copy customers",
  "View SSN",
  "Access all customers",
  "Reassign salesperson",
  "Access desking",
  "Push customer to DMS",
  "Mark deals as sold",
  "Unwind deals",
  "Complete showroom visits",
  "Delete showroom visits",
  "Edit tasks",
  "Dismiss tasks",
  "Access trade appraisals",
  "Override overdue task block",
  "Manage desking templates",
  "View desking management report",
  "Access desk log",
  "Access service appt. dashboard",
  "View gross",
  "View vehicle cost",
  "View equity",
  "Mark leads bad",
  "Mark leads lost",
  "Change custom process",
  "Access Facebook Messenger",
  "Push to credit portal",
  "Access/Enable Enterprise Customer",
  "Access Customer Intelligence",
  "Access AMP Opportunity List",
  "View OEM Launchpad Incentives",
  "Enable Generative AI capabilities"
];

const enterpriseSalesperson = [
  "View gross",
  "View Appraisals (ACV)",
  "Access trade appraisals",
  "View equity",
  "View desking management report",
  "Access/Enable Enterprise Customer"
];

const enterpriseManager = [
  "View gross",
  "View Appraisals (ACV)",
  "Access trade appraisals",
  "View equity",
  "View desking management report",
  "Access/Enable Enterprise Customer"
];
// Return the relevant permissions for the provided parentRole
// This is a temporary feature. This is necessary because
// at this time we can only support adding permissions to a role.
// In an upcoming version we can delete this file and provide the list
// of all permissions regardless of parentRole selected.
export function getPermissionsForRole(parentRole, permissions) {
  if (!parentRole) return [];

  switch (parentRole.name.replace(/\s/g, "")) {
    case "Admin":
      return adminPermissions.reduce((prev, cur) => {
        const perm = permissions.find(p => p.displayName === cur);
        if (perm) prev.push(perm);
        return prev;
      }, []);

    case "Manager":
      return managerPermissions.reduce((prev, cur) => {
        const perm = permissions.find(p => p.displayName === cur);
        if (perm) prev.push(perm);
        return prev;
      }, []);

    case "Salesperson":
      return salesPersonPermissions.reduce((prev, cur) => {
        const perm = permissions.find(p => p.displayName === cur);
        if (perm) prev.push(perm);
        return prev;
      }, []);

    case "EnterpriseSalesperson":
      return enterpriseSalesperson.reduce((prev, cur) => {
        const perm = permissions.find(p => p.displayName === cur);
        if (perm) prev.push(perm);
        return prev;
      }, []);

    case "EnterpriseManager":
      return enterpriseManager.reduce((prev, cur) => {
        const perm = permissions.find(p => p.displayName === cur);
        if (perm) prev.push(perm);
        return prev;
      }, []);
    case "None":
      return permissions.filter(p => p.displayName === "Access mobile apps");

    default:
      throw new Error("Unhandled parentRole: " + parentRole.name);
  }
}
